import Calculator from './Calculator';
import { createTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import {
  Card,
  CssBaseline,
  Grid,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material';
import Layout from 'components/Layout';
import { generateTheme } from 'mui-theme-generator';
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { instance } from './track.js';
import ConfigProvider from 'context';
import TermsAcceptance from 'components/TermsAcceptance';

function App() {
  const { enableLinkTracking } = useMatomo();
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: 'Page title', // optional
      href: 'https://LINK.TO.PAGE', // optional
      customDimensions: [
        {
          id: 1,
          value: process.env.REACT_APP_STAGE,
        },
        {
          id: 2,
          value: process.env.REACT_APP_VERSION,
        },
      ],
    });
  }, []);
  const theme2 = generateTheme({
    primaryColor: '#4caf50',
    secondaryColor: '#9c27b0',
    mode: 'light',
  });
  let theme = useMemo(() => createTheme(theme2));
  theme = responsiveFontSizes(theme);
  enableLinkTracking();
  return (
    <MatomoProvider value={instance}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ConfigProvider>
          <Grid
            container
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgb(234, 234, 234)',
            }}
          >
            <Card
              elevation={5}
              sx={{
                maxWidth: '800px',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
              }}
            >
              <TermsAcceptance />
              <Layout />
              <Calculator />
            </Card>
          </Grid>
        </ConfigProvider>
      </ThemeProvider>
    </MatomoProvider>
  );
}

export default App;
