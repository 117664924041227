/* eslint-disable react/prop-types */
import { Grid, Typography, useTheme } from '@mui/material';
import util from 'helpers/util';

const Overview = ({ minvalue, maxValue, averageProfit }) => {
  const theme = useTheme();
  return (
    <Grid
      margin={2}
      padding={1}
      border={`2px solid ${theme.palette.grey[900]}`}
    >
      {/* <Typography
        variant="h6"
        component="h6"
        sx={{ textAlign: 'center', width: '100%' }}
      >
        RESULTADO MAIS PROVÁVEL
      </Typography> */}
      <Typography>
        O seu Lucro Operacional mais provável é de{' '}
        <Typography
          fontWeight="bold"
          variant="caption"
          fontSize="1rem"
          color={
            averageProfit > 0
              ? theme.palette.success.main
              : theme.palette.error.main
          }
        >
          {util.methods.utilCurrencyFormatter(averageProfit)}
        </Typography>
        {averageProfit >= 0
          ? ' de lucro por hectare '
          : ' de prejuizo por hectare '}
        com o uso da cultivar BRS Estribo de capim-sudão na pecuária de corte.
        <br />O lucro poderá variar de{' '}
        <Typography
          fontWeight="bold"
          variant="caption"
          fontSize="1rem"
          color={
            minvalue > 0 ? theme.palette.success.main : theme.palette.error.main
          }
        >
          {util.methods.utilCurrencyFormatter(minvalue)}
        </Typography>
        {minvalue >= 0 ? ' de lucro ' : ' de prejuizo '}
        por hectare até{' '}
        <Typography
          fontWeight="bold"
          variant="caption"
          fontSize="1rem"
          color={
            maxValue > 0 ? theme.palette.success.main : theme.palette.error.main
          }
        >
          {util.methods.utilCurrencyFormatter(maxValue)}
        </Typography>
        {maxValue >= 0 ? ' de lucro ' : ' de prejuizo '}
        por hectare, dependendo das combinações de preço, custo e produtividade.
      </Typography>
    </Grid>
  );
};

export default Overview;
