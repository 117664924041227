/* eslint-disable react/prop-types */
import getAssessment from 'components/Assessment';
const { Grid, Typography } = require('@mui/material');

const ScenariosDescription = ({ price, cost, productivity }) => {
  return (
    <Grid padding={2}>
      <Typography variant="h5" xs={12} textAlign="center" m={3}>
        VALORES CRÍTICOS
      </Typography>
      <Typography xs={12} display="flex" flexDirection="column" gap={1}>
        {getAssessment(price, cost, productivity, 'cost')}
        {getAssessment(price, cost, productivity, 'price')}
        {getAssessment(price, cost, productivity, 'productivity')}
      </Typography>
    </Grid>
  );
};

export default ScenariosDescription;
