/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Button, useTheme, Grid, Typography } from '@mui/material';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyInput from 'react-currency-input-field';

const CalcProdForm = ({ produtividade, setProductivity, close }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [lotacao, setLotacao] = useState('2,5');
  const [gmd, setGmd] = useState('0,9');
  const [pastejo, setPastejo] = useState('134');
  const [novaProdutividade, setNovaProdutividade] = useState(null);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  function calcular() {
    const numericLotacao = parseFloat(
      lotacao.replace(/\./g, '').replace(',', '.'),
    );
    const numericGmd = parseFloat(gmd.replace(/\./g, '').replace(',', '.'));
    const numericPastejo = parseFloat(
      pastejo.replace(/\./g, '').replace(',', '.'),
    );

    if (
      isNaN(numericLotacao) ||
      isNaN(numericGmd) ||
      isNaN(numericPastejo) ||
      numericLotacao <= 0 ||
      numericGmd <= 0 ||
      numericPastejo <= 0
    ) {
      alert('Por favor, insira valores válidos maiores que zero.');
      return;
    }

    const produtividadeCalculada = numericGmd * numericLotacao * numericPastejo;

    const formattedProdutividade = produtividadeCalculada
      .toFixed(2)
      .toString()
      .replace('.', ',');

    setNovaProdutividade(formattedProdutividade);
  }

  function cancelar() {
    setNovaProdutividade(null);
  }

  function confirmar() {
    const numericProdutividade = parseFloat(
      novaProdutividade.replace(/\./g, '').replace(',', '.'),
    );
    setProductivity(numericProdutividade);
    close();
  }

  return (
    <Grid container xs={12} border="1px solid grey">
      <Button
        href="#"
        variant="text"
        onClick={handleClickOpen}
        color="secondary"
      >
        Deseja calcular a sua Produtividade? <ArrowDropDownIcon />
      </Button>

      {open && (
        <Grid container xs={12} justifyContent="space-between">
          <Grid container xs={12}>
            {/* Lotação animal */}
            <Grid container xs={6} mt={2}>
              <label>Lotação animal (animais/hectare): </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={lotacao}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setLotacao(value || '0')}
              />
            </Grid>

            {/* GMD */}
            <Grid container xs={6} mt={2}>
              <label>GMD (kg/dia): </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={gmd}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setGmd(value || '0')}
              />
            </Grid>

            {/* Dias de pastejo */}
            <Grid container xs={6} mt={2}>
              <label>Dias de pastejo: </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={pastejo}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={false}
                onValueChange={(value) => setPastejo(value || '0')}
              />
            </Grid>

            {novaProdutividade !== null && (
              <Grid xs={12} mt={2}>
                <Typography>
                  A sua produtividade é de{' '}
                  <Typography
                    sx={{
                      display: 'inline-block',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {novaProdutividade}
                  </Typography>{' '}
                  kg de peso vivo / hectare. Confirma alteração?
                </Typography>
                <Button onClick={cancelar} autoFocus>
                  Cancelar
                </Button>
                <Button onClick={confirmar} autoFocus>
                  Confirmar
                </Button>
              </Grid>
            )}

            <Grid xs={12} padding="1rem 1rem">
              <Button
                onClick={calcular}
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.warning.main,
                  color: '#ffffff',
                }}
              >
                <CalculateRoundedIcon /> Calcular a produtividade por hectare
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CalcProdForm;
