import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

// eslint-disable-next-line react/prop-types
const FeatureNotReadyDialog = ({ isOpen, handleClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        display="flex"
        justifyContent="center"
        gap={1}
        alignItems="center"
      >
        <InfoRounded />
        <Typography variant="h5">Oops.</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Esta aplicação ainda está em versão beta e portanto não possui esta
          feature.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeatureNotReadyDialog;
