import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

const ProdTable = () => {
  const data = [
    { description: 'lotação animal', amount: '2,5 animais / hectare' },
    { description: 'GMD', amount: '0,9 kg/dia' },
    { description: 'pastejo', amount: '130 dias' },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Tabela de Produtividade">
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProdTable;
