/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import util from 'helpers/util';

export default function getAssessment(price, cost, prod, type) {
  switch (type) {
    case 'cost':
      return (
        <Typography>
          <Typography variant="h5" fontWeight="bold">
            Qual a produtividade mínima para se ter lucro?
          </Typography>
          <Typography variant="subtitle1">
            O Lucro Operacional começa a surgir quando a conseguimos alcançar
            uma produção de, no mínimo,
            <b>&nbsp;{~~(cost / price)} kg</b> de peso vivo por hectare. Isso
            considerando um custo de implantação da pastagem de
            <b>&nbsp;{util.methods.utilCurrencyFormatter(cost)}</b> por hectare
            e um preço de venda de &nbsp;{' '}
            <b>{util.methods.utilCurrencyFormatter(price)}</b>&nbsp; por quilo
            vivo. Em outras palavras, quando conseguimos vender o suficiente
            para cobrir os custos de produção, começamos a ter lucro.
          </Typography>
        </Typography>
      );
    case 'price':
      return (
        <Typography>
          <Typography variant="h5" fontWeight="bold">
            Qual o custo máximo para ter lucro?
          </Typography>
          <Typography variant="subtitle1">
            O Lucro Operacional começa a ocorrer quando o custo de implantação
            da pastagem é de, no máximo, &nbsp;
            <b>{util.methods.utilCurrencyFormatter(price * prod)}</b>&nbsp; por
            hectare. Isso considerando um preço de comercialização de
            <b>&nbsp;{util.methods.utilCurrencyFormatter(price)}</b> por quilo
            vivo e uma produtividade de
            <b> {prod} kg</b> de peso por hectare. Em outras palavras, quando
            temos um custo menor que o faturamento com a venda dos animais,
            começamos a ter lucro.
          </Typography>
        </Typography>
      );
    case 'productivity':
      return (
        <Typography>
          <Typography variant="h5" fontWeight="bold">
            Qual o preço mínimo para ter lucro?
          </Typography>
          <Typography variant="subtitle1">
            O Lucro Operacional começa a existir quando o preço mínimo
            comercializado for de, no mínimo, &nbsp;
            <b>{util.methods.utilCurrencyFormatter(cost / prod)}</b>&nbsp; por
            quilo vivo. Isso considerando uma produtividade de &nbsp;
            <b>{prod} kg</b> de peso por hectare e um custo de implantação de
            pastagem de &nbsp;<b>{util.methods.utilCurrencyFormatter(cost)}</b>
            &nbsp; por hectare. Em outras palavras, quando temos um faturamento
            com a venda dos animais maior que o custo da pastagem, começamos a
            ter lucro.
          </Typography>
        </Typography>
      );
  }
}
