/* eslint-disable react/prop-types */
import {
  Button,
  Typography,
  Grid,
  useTheme,
  Box,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import Configurations from 'sections/Configurations';
import ProductivityHelp from 'components/ProductivityHelp';
import CostHelp from 'components/CostHelp';
import { useState } from 'react';
import { HelpRounded } from '@mui/icons-material';

const InputField = styled.input`
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
`;

const InputForm = ({
  cost,
  setCost,
  price,
  setPrice,
  productivity,
  setProductivity,
  onCalculate,
}) => {
  const theme = useTheme();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCalculate = () => {
    // Converter strings para números para validação
    const numericCost = parseFloat(cost.replace(/\./g, '').replace(',', '.'));
    const numericPrice = parseFloat(price.replace(/\./g, '').replace(',', '.'));

    // Validação dos campos
    if (!numericCost || isNaN(numericCost) || numericCost < 200) {
      setSnackbarMessage(
        'Intervalo Inválido! O campo "Custo" precisa ter valor mínimo de R$ 200',
      );
      setSnackbarOpen(true);
      return;
    }
    if (!numericPrice || isNaN(numericPrice) || numericPrice < 2) {
      setSnackbarMessage(
        'Intervalo Inválido! O campo "Preço" precisa ter valor mínimo de R$ 2,00',
      );
      setSnackbarOpen(true);
      return;
    }
    if (!productivity || isNaN(productivity) || productivity < 200) {
      setSnackbarMessage(
        'Intervalo Inválido! O campo "Produtividade" precisa ter valor mínimo de 200',
      );
      setSnackbarOpen(true);
      return;
    }

    // Se todos os campos forem válidos, chama a função de cálculo
    onCalculate(numericCost, numericPrice);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Grid
      container
      xs={12}
      alignContent="flex-start"
      paddingRight="1rem"
      paddingLeft="1rem"
      paddingBottom="1rem"
    >
      <Typography variant="h6" sx={{ marginBottom: 1, width: '100%' }}>
        Para calcular o seu lucro, EDITE os campos abaixo:
      </Typography>
      {/* Campo de Custo */}
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <label>Custo por hectare (R$/hectare): </label>
        </Box>
        <CurrencyInput
          style={{
            padding: '0.5em',
            border: '1px solid #ccc',
            borderRadius: '3px',
            width: '100%',
            maxWidth: '15.5rem',
          }}
          id="custo"
          name="custo"
          value={cost}
          decimalSeparator=","
          groupSeparator="."
          allowDecimals={true}
          decimalsLimit={2}
          onValueChange={(value) => setCost(value || '')}
        />
        {/* Ajuda Custo */}
        <CostHelp custo={cost} setCusto={setCost} />
      </Grid>
      {/* Configurações */}
      <Configurations cost={cost} price={price} productivity={productivity} />
      {/* Campo de Preço */}
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <label>Preço de comercialização (R$/kg vivo):</label>
        </Box>
        <CurrencyInput
          style={{
            padding: '0.5em',
            border: '1px solid #ccc',
            borderRadius: '3px',
            width: '100%',
            maxWidth: '15.5rem',
          }}
          id="preco"
          name="preco"
          value={price}
          decimalSeparator=","
          groupSeparator="."
          allowDecimals={true}
          decimalsLimit={2}
          onValueChange={(value) => setPrice(value || '')}
        />
        <IconButton
          href="https://www.ufrgs.br/nespro/"
          rel="noreferrer"
          target="_blank"
        >
          <HelpRounded />
        </IconButton>
      </Grid>
      {/* Campo de Produtividade */}
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <label>Produtividade (kg peso vivo/hectare): </label>
        </Box>

        <InputField
          style={{ maxWidth: '15.5rem' }}
          type="number"
          value={productivity}
          onChange={(e) =>
            setProductivity(e.target.value ? parseFloat(e.target.value) : '')
          }
        />
        {/* Ajuda Produtividade */}
        <ProductivityHelp
          productivity={productivity}
          setProductivity={setProductivity}
        />
      </Grid>
      {/* Botão Calcular */}
      <Grid xs={12} padding="1rem" justifyContent="center">
        <Button
          onClick={handleCalculate}
          variant="contained"
          sx={{
            width: '100%',
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            maxWidth: '16.5rem',
          }}
        >
          <CalculateRoundedIcon /> Calcule o lucro por hectare
        </Button>
      </Grid>
      {/* Snackbar para mensagens de erro */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default InputForm;
