/* eslint-disable react/prop-types */
import React from 'react';

const defaultConfig = {
  costInterval: 100,
  priceInterval: 1,
  productivityInterval: 100,
};

const recordedConfig = localStorage.getItem('config-sudao');
const initialConfig = recordedConfig
  ? JSON.parse(recordedConfig)
  : defaultConfig;
const isConfigOpen = false;
export const ConfigContext = React.createContext({
  config: initialConfig,
  isConfigOpen,
});

const ConfigProvider = (props) => {
  const [isConfigOpen, setIsConfigOpen] = React.useState(false);
  const [config, setConfig] = React.useState(initialConfig);

  function toggleConfigOpen() {
    setIsConfigOpen(!isConfigOpen);
  }

  function recordConfig(newConfig) {
    setConfig(newConfig);
    localStorage.setItem('config-sudao', JSON.stringify(newConfig));
  }
  return (
    <ConfigContext.Provider
      value={{
        config,
        isConfigOpen,
        recordConfig,
        toggleConfigOpen,
        setConfig,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
