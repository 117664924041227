import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CodeRounded, InfoRounded } from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';

export default function About() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const year = new Date().getFullYear();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        href="#"
        variant="outlined"
        startIcon={<InfoRounded />}
        sx={{
          backgroundColor: theme.palette.primary.main,
          ':hover': {
            backgroundColor: theme.palette.primary.light,
          },
          color: '#ffffff',
        }}
        onClick={handleClickOpen}
      >
        Sobre
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          display="flex"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <InfoRounded />
          <Typography variant="h5">
            Sobre o Cenários Capim Sudão {process.env.REACT_APP_VERSION}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este webapp da Embrapa realiza simulações de cenários de viabilidade
            e lucratividade do uso de capim sudão como pastagem na bovinocultura
            de corte, através do uso de uma interface simples e intuitiva, cujo
            objetivo é agilizar o processo de decisão para os produtores rurais,
            ao providenciar diferentes cenários de lucratividade em função da
            variação de preço do boi, custo e produtividade. Basta entrar com os
            valores fixos e intervalos para gerar a simulação e clicar em
            calcular, sendo que o sistema gerará um cruzamento de variações dos
            valores, de forma visual e intuitiva, onde o valor em verde mais
            forte é o maior valor de lucro possível, partindo da metade da
            variação, o amarelo é a primeira metade da variação de lucro e o
            valor vermelho mais forte é o valor do maior prezuizo estimado. Se
            for possível, dê seu feedback no formulário de críticas/sugestões,
            pois a sua opinião como usuário é muito relevante para o nosso
            planejamento de melhoria contínua.
            <br />
            <br />
            <Typography variant="subtitle2">
              Desenvolvido pela{' '}
              <Button
                href="https://www.lotuswebsystems.com"
                rel="noreferrer"
                target="_blank"
                variant="outlined"
                startIcon={<CodeRounded />}
              >
                LOTUS WEB SYSTEMS
              </Button>{' '}
              em parceria com a Embrapa.
            </Typography>
            <br />
            <Typography variant="caption">
              Copyright &#169; {year} Empresa Brasileira de Pesquisa
              Agropecuária &#174;
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
