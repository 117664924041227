/* eslint-disable react/prop-types */
// Configurations.jsx

import { BuildRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Slider,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import { ConfigContext } from 'context';
import * as React from 'react';

const Configurations = ({ cost, price, productivity }) => {
  const defaultConfig = {
    costInterval: 100,
    priceInterval: 1,
    productivityInterval: 100,
  };
  const defaultValues = {
    cost: '2000,00',
    price: '9,50',
    productivity: 300,
  };

  // Function to convert string to number
  const parseStringToNumber = (str) => {
    return parseFloat(str.replace(/\./g, '').replace(',', '.'));
  };

  const currentValues = {
    cost: cost || defaultValues.cost,
    price: price || defaultValues.price,
    productivity: productivity || defaultValues.productivity,
  };

  // Convert cost and price strings to numbers for calculations
  const numericCost = parseStringToNumber(currentValues.cost);
  const numericPrice = parseStringToNumber(currentValues.price);
  const numericProductivity = parseFloat(currentValues.productivity);

  const { config, isConfigOpen, toggleConfigOpen, recordConfig, setConfig } =
    React.useContext(ConfigContext);

  const [values, setValues] = React.useState(config || defaultConfig);

  const onSubmit = () => {
    recordConfig(values);
    setConfig(values);
    toggleConfigOpen();
  };

  const resetConfig = () => {
    recordConfig(defaultConfig);
    setValues(defaultConfig);
    toggleConfigOpen();
  };

  const handleIntervalChange = (event, name) => {
    setValues({ ...values, [name]: event.target.value });
  };

  // Function to format numbers back to strings with commas
  const formatNumberToString = (num) => {
    return num.toFixed(2).toString().replace('.', ',');
  };

  return (
    <Dialog
      open={isConfigOpen}
      onClose={toggleConfigOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        display="flex"
        justifyContent="center"
        gap={1}
        alignItems="center"
      >
        <BuildRounded />
        <Typography variant="h5">Configurações</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body">
            Configure os valores dos intervalos para o cálculo dos cenários.{' '}
            <br />
            Os intervalos circulam em torno do preço, produtividade e custo,
            sendo que o cálculo é feito com dois intervalos para cima e para
            baixo.
          </Typography>

          {/* Custo */}
          <Grid xs={12} padding="1rem 1rem">
            <Box>
              <FormControl fullWidth>
                <InputLabel id="cost-interval-select-label">
                  Intervalo de Custo
                </InputLabel>
                <Select
                  labelId="cost-interval-select-label"
                  id="cost-interval-select"
                  value={values.costInterval}
                  label="Intervalo de Custo"
                  onChange={(e) => handleIntervalChange(e, 'costInterval')}
                >
                  <MenuItem value={50}>R$ 50</MenuItem>
                  <MenuItem value={100}>R$ 100</MenuItem>
                </Select>
              </FormControl>
            </Box>
            Variação de custo (R$/hectare):{' '}
            <Slider
              size="small"
              sx={{ maxWidth: '20rem' }}
              value={[
                numericCost - 2 * values.costInterval,
                numericCost - values.costInterval,
                numericCost,
                numericCost + values.costInterval,
                numericCost + 2 * values.costInterval,
              ]}
              valueLabelDisplay="auto"
              marks={[
                {
                  value: numericCost - 2 * values.costInterval,
                  label: formatNumberToString(
                    numericCost - 2 * values.costInterval,
                  ),
                },
                {
                  value: numericCost - values.costInterval,
                  label: formatNumberToString(
                    numericCost - values.costInterval,
                  ),
                },
                {
                  value: numericCost,
                  label: formatNumberToString(numericCost),
                },
                {
                  value: numericCost + values.costInterval,
                  label: formatNumberToString(
                    numericCost + values.costInterval,
                  ),
                },
                {
                  value: numericCost + 2 * values.costInterval,
                  label: formatNumberToString(
                    numericCost + 2 * values.costInterval,
                  ),
                },
              ]}
              min={numericCost - 2 * values.costInterval}
              max={numericCost + 2 * values.costInterval}
              disabled
            />
          </Grid>

          {/* Preço */}
          <Grid xs={12} padding="1rem 1rem">
            <Box>
              <FormControl fullWidth>
                <InputLabel id="price-interval-select-label">
                  Intervalo de Preço
                </InputLabel>
                <Select
                  labelId="price-interval-select-label"
                  id="price-interval-select"
                  value={values.priceInterval}
                  label="Intervalo de Preço"
                  onChange={(e) => handleIntervalChange(e, 'priceInterval')}
                >
                  <MenuItem value={0.5}>R$ 0,50</MenuItem>
                  <MenuItem value={1}>R$ 1,00</MenuItem>
                </Select>
              </FormControl>
            </Box>
            Variação de preço (R$/kg vivo):{' '}
            <Slider
              size="small"
              sx={{ maxWidth: '20rem' }}
              value={[
                numericPrice - 2 * values.priceInterval,
                numericPrice - values.priceInterval,
                numericPrice,
                numericPrice + values.priceInterval,
                numericPrice + 2 * values.priceInterval,
              ]}
              valueLabelDisplay="auto"
              marks={[
                {
                  value: numericPrice - 2 * values.priceInterval,
                  label: formatNumberToString(
                    numericPrice - 2 * values.priceInterval,
                  ),
                },
                {
                  value: numericPrice - values.priceInterval,
                  label: formatNumberToString(
                    numericPrice - values.priceInterval,
                  ),
                },
                {
                  value: numericPrice,
                  label: formatNumberToString(numericPrice),
                },
                {
                  value: numericPrice + values.priceInterval,
                  label: formatNumberToString(
                    numericPrice + values.priceInterval,
                  ),
                },
                {
                  value: numericPrice + 2 * values.priceInterval,
                  label: formatNumberToString(
                    numericPrice + 2 * values.priceInterval,
                  ),
                },
              ]}
              min={numericPrice - 2 * values.priceInterval}
              max={numericPrice + 2 * values.priceInterval}
              disabled
            />
          </Grid>

          {/* Produtividade */}
          <Grid xs={12} padding="1rem 1rem">
            <Box>
              <FormControl fullWidth>
                <InputLabel id="productivity-interval-select-label">
                  Intervalo de Produtividade
                </InputLabel>
                <Select
                  labelId="productivity-interval-select-label"
                  id="productivity-interval-select"
                  value={values.productivityInterval}
                  label="Intervalo de Produtividade"
                  onChange={(e) =>
                    handleIntervalChange(e, 'productivityInterval')
                  }
                >
                  <MenuItem value={50}>50 kg</MenuItem>
                  <MenuItem value={100}>100 kg</MenuItem>
                </Select>
              </FormControl>
            </Box>
            Variação de produtividade (kg vivo/hectare):{' '}
            <Slider
              size="small"
              sx={{ maxWidth: '20rem' }}
              value={[
                numericProductivity - 2 * values.productivityInterval,
                numericProductivity - values.productivityInterval,
                numericProductivity,
                numericProductivity + values.productivityInterval,
                numericProductivity + 2 * values.productivityInterval,
              ]}
              valueLabelDisplay="auto"
              marks={[
                {
                  value: numericProductivity - 2 * values.productivityInterval,
                  label: numericProductivity - 2 * values.productivityInterval,
                },
                {
                  value: numericProductivity - values.productivityInterval,
                  label: numericProductivity - values.productivityInterval,
                },
                {
                  value: numericProductivity,
                  label: numericProductivity,
                },
                {
                  value: numericProductivity + values.productivityInterval,
                  label: numericProductivity + values.productivityInterval,
                },
                {
                  value: numericProductivity + 2 * values.productivityInterval,
                  label: numericProductivity + 2 * values.productivityInterval,
                },
              ]}
              min={numericProductivity - 2 * values.productivityInterval}
              max={numericProductivity + 2 * values.productivityInterval}
              disabled
            />
          </Grid>

          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            fullWidth
            sx={{ mt: 2 }}
          >
            Gravar Configurações
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetConfig} autoFocus>
          Limpar Configuração
        </Button>
        <Button onClick={toggleConfigOpen} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Configurations;
