/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HelpRounded, QuestionMarkRounded } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import CostCalcForm from './costCalcForm';
import CostTable from './CostTable';

export default function CostHelp({ custo: cost, setCusto: setCost }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton href="#" variant="text" onClick={handleClickOpen}>
        <HelpRounded />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          display="flex"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <QuestionMarkRounded />
          <Typography variant="h5">Calculo de Custo</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              O valor do custo obtém-se através da soma dos valores padrões
              gastos por hectare com os seguintes itens:
            </Typography>
            <CostTable />
            <Grid xs={12}>
              <CostCalcForm cost={cost} setCost={setCost} close={handleClose} />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
