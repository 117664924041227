import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

const CostTable = () => {
  const data = [
    { description: 'adubação de base', amount: 'R$ 400,00' },
    { description: 'adubação nitrogenada', amount: 'R$ 250,00' },
    { description: 'calcário', amount: 'R$ 0,00' },
    { description: 'combustíveis', amount: 'R$ 150,00' },
    { description: 'defensivos agrícolas', amount: 'R$ 100,00' },
    { description: 'sementes', amount: 'R$ 100,00' },
    { description: 'serviços mecanizados', amount: 'R$ 150,00' },
    { description: 'outros', amount: 'R$ 0,00' },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Tabela de custos">
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CostTable;
