import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const TermsAcceptance = () => {
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    // Verifica no localStorage se os termos já foram aceitos
    const termsAccepted = localStorage.getItem('termsAccepted');
    if (!termsAccepted) {
      // Se não foram aceitos, abre o diálogo
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    // Salva a aceitação no localStorage
    localStorage.setItem('termsAccepted', 'true');
    setAccepted(true);
    // Fecha o diálogo
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      aria-labelledby="terms-dialog-title"
    >
      <DialogTitle id="terms-dialog-title">Termos e Condições</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Bem-vindo ao EstriboSim! Por favor, leia cuidadosamente os nossos
          termos e condições disponíveis no link abaixo antes de continuar.
        </Typography>
        <Typography variant="body2" gutterBottom>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.embrapa.br/acessoainformacao/aviso-de-privacidade-para-aplicativo-movel-sem-coleta-de-dados-pessoais"
          >
            Aviso de privacidade para aplicativo sem coleta de dados pessoais
          </a>
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={accepted}
              onChange={(e) => setAccepted(e.target.checked)}
              name="acceptTerms"
            />
          }
          label="Eu li e aceito os termos e condições."
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAccept}
          color="primary"
          variant="contained"
          disabled={!accepted}
        >
          Aceitar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAcceptance;
