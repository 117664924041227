/* eslint-disable react/prop-types */
import React from 'react';
import { Button, useTheme, Grid, Typography } from '@mui/material';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyInput from 'react-currency-input-field';

const CostCalcForm = ({ cost, setCost, close }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [newCost, setNewCost] = React.useState(null);
  const [adubBase, setAdubBase] = React.useState('700,00');
  const [adubNitro, setAdubNitro] = React.useState('400,00');
  const [calcario, setCalcario] = React.useState('0,00');
  const [fuel, setFuel] = React.useState('250,00');
  const [defensivos, setDefensivos] = React.useState('150,00');
  const [seeds, setSeeds] = React.useState('150,00');
  const [mech, setMech] = React.useState('250,00');
  const [otherCost, setOtherCost] = React.useState('0,00');

  const handleClickOpen = () => {
    setOpen(!open);
  };

  function calcular() {
    const totalCost =
      parseFloat(adubBase.replace(/\./g, '').replace(',', '.')) +
      parseFloat(adubNitro.replace(/\./g, '').replace(',', '.')) +
      parseFloat(calcario.replace(/\./g, '').replace(',', '.')) +
      parseFloat(fuel.replace(/\./g, '').replace(',', '.')) +
      parseFloat(defensivos.replace(/\./g, '').replace(',', '.')) +
      parseFloat(seeds.replace(/\./g, '').replace(',', '.')) +
      parseFloat(mech.replace(/\./g, '').replace(',', '.')) +
      parseFloat(otherCost.replace(/\./g, '').replace(',', '.'));

    // Formatar o totalCost de volta para string com separador decimal
    const formattedTotalCost = totalCost
      .toFixed(2)
      .toString()
      .replace('.', ',');

    setNewCost(formattedTotalCost);
  }

  function cancelar() {
    setNewCost(null);
  }

  function confirmar() {
    setCost(newCost);
    close();
  }

  return (
    <Grid container xs={12} border="1px solid grey">
      <Button
        href="#"
        variant="text"
        onClick={handleClickOpen}
        color="secondary"
      >
        Deseja calcular o seu Custo? <ArrowDropDownIcon />
      </Button>

      {open && (
        <Grid container xs={12} justifyContent="space-between">
          <Grid container xs={12}>
            {/* Adubação de base */}
            <Grid container xs={6} mt={2}>
              <label>Adubação de base: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={adubBase}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setAdubBase(value || '0,00')}
              />
            </Grid>

            {/* Adubação Nitrogenada */}
            <Grid container xs={6} mt={2}>
              <label>Adubação Nitrogenada: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={adubNitro}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setAdubNitro(value || '0,00')}
              />
            </Grid>

            {/* Calcário */}
            <Grid container xs={6} mt={2}>
              <label>Calcário: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={calcario}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setCalcario(value || '0,00')}
              />
            </Grid>

            {/* Combustíveis */}
            <Grid container xs={6} mt={2}>
              <label>Combustíveis: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={fuel}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setFuel(value || '0,00')}
              />
            </Grid>

            {/* Defensivos Agrícolas */}
            <Grid container xs={6} mt={2}>
              <label>Defensivos Agrícolas: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={defensivos}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setDefensivos(value || '0,00')}
              />
            </Grid>

            {/* Sementes */}
            <Grid container xs={6} mt={2}>
              <label>Sementes: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={seeds}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setSeeds(value || '0,00')}
              />
            </Grid>

            {/* Serviços Mecanizados */}
            <Grid container xs={6} mt={2}>
              <label>Serviços Mecanizados: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={mech}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setMech(value || '0,00')}
              />
            </Grid>

            {/* Outros */}
            <Grid container xs={6} mt={2}>
              <label>Outros: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                value={otherCost}
                decimalSeparator=","
                groupSeparator="."
                allowDecimals={true}
                decimalsLimit={2}
                onValueChange={(value) => setOtherCost(value || '0,00')}
              />
            </Grid>

            {newCost !== null && (
              <Grid xs={12} mt={2}>
                <Typography>
                  O seu custo atualmente é de{' '}
                  <Typography
                    sx={{
                      display: 'inline-block',
                      color: theme.palette.primary.main,
                    }}
                  >
                    R$ {newCost}
                  </Typography>
                  . Confirma alteração?
                </Typography>
                <Button onClick={cancelar} autoFocus>
                  Cancelar
                </Button>
                <Button onClick={confirmar} autoFocus>
                  Confirmar
                </Button>
              </Grid>
            )}

            <Grid xs={12} padding="1rem 1rem">
              <Button
                onClick={calcular}
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.grey[700],
                  color: '#ffffff',
                }}
              >
                <CalculateRoundedIcon /> Calcular o custo por hectare
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CostCalcForm;
