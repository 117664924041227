import { AddReactionRounded, BuildCircleRounded } from '@mui/icons-material';
import {
  AppBar,
  useTheme,
  Grid,
  Typography,
  Button,
  Card,
} from '@mui/material';
import embrapa from 'assets/embrapa.png';
import About from 'components/About';
import { ConfigContext } from 'context';
import { useContext } from 'react';
/* import Feedback from 'components/Feedback'; */

const Navbar = () => {
  const theme = useTheme();
  const { toggleConfigOpen } = useContext(ConfigContext);

  const handleClickOpen = (e) => {
    e.preventDefault();
    toggleConfigOpen();
  };

  return (
    <AppBar
      sx={{
        position: 'relative',
        background: theme.palette.primary.main,
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: '1.25rem',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Card
        elevation={8}
        sx={{
          position: 'absolute',
          zIndex: '1000',
          right: 0,
          top: 0,
          backgroundColor: theme.palette.warning.main,
          translate: '3rem',
          rotate: '45deg',
          padding: '0.5rem 3.5rem',
        }}
      >
        <Typography textTransform="uppercase" color="white" variant="caption">
          Beta
        </Typography>
      </Card>
      <Grid
        container
        xs={12}
        padding="1rem"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          container
          xs={12}
          sm={8}
          justifyContent="center"
          gap="1rem"
          alignItems="center"
        >
          <Grid
            xs={12}
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Typography textAlign="center">
              <img
                src={embrapa}
                alt="logo estriboSIm"
                style={{ maxWidth: '150px' }}
              />
            </Typography>

            <Typography variant="h3" textAlign="center">
              EstriboSim
            </Typography>
            <Typography textAlign="center">
              Ferramenta de simulação e análise bioeconômica da cultivar BRS
              Estribo de capim-sudão
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={4}
          lg={4}
          gap="1rem"
          textTransform="uppercase"
          fontSize="1rem"
          alignItems="center"
          justifyContent="flex-end"
          paddingRight="4rem"
        >
          <Button
            href="#"
            variant="outlined"
            startIcon={<BuildCircleRounded />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              ':hover': {
                backgroundColor: theme.palette.primary.light,
              },
              color: '#ffffff',
            }}
            onClick={handleClickOpen}
          >
            CONFIGURAÇÕES
          </Button>
          <Button
            href="https://forms.gle/M2CcVgCrsXxkwc8i9"
            rel="noreferrer"
            target="_blank"
            variant="outlined"
            startIcon={<AddReactionRounded />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              ':hover': {
                backgroundColor: theme.palette.primary.light,
              },
              color: '#ffffff',
            }}
          >
            DÊ SUA OPINIÃO
          </Button>
          <About />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Navbar;
